import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PortableText from 'react-portable-text';

import { Grid, Typography } from '@material-ui/core';

export const IndustryBlurb = ({ industry }) => {
	return (
		<Grid item lg={4} md={6} xs={12} container>
		
			<Grid container xs={12} direction='column'>
				<Typography variant='h5' color='primary'>
				<FontAwesomeIcon
					icon={['fad', industry.faIcon]}
					size='3x'
					style={{
						height: '44px',
						width: '44px',
						color: industry.accentColor[0]?.hexValue ?? '#002D5C',
						border: `1px solid #E5EAF4`,
						borderRadius: '8px',
						background: '#E5EAF4',
						padding: '6px',
						textAlign: 'center',
						marginRight: '8px',
						marginBottom: '-5px',
					}}/>
					{industry.homeHeroPillTitle}
				</Typography>
				<PortableText
					content={industry._rawSynopsis}
					serializers={{
						normal: ({ children }) => (
							<Typography
								variant='body1'
								style={{ lineHeight: '32px', marginLeft: '55px' }}>
								{children}
							</Typography>
						),
					}}
				/>
			</Grid>
		</Grid>
	);
};
