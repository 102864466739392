import React, { useState, useEffect, useRef } from 'react';
import loadable from '@loadable/component';

import { Container, useMediaQuery, Typography, Grid } from '@material-ui/core';

// import { BodyFeature } from './BodyFeature';
import { MobileFeatures } from './Mobile/MobileFeatures';
import { BodyFeatureCard } from './BodyFeatureCard';
import { LogoLeftCtaCard } from '../../General/LogoLeftCtaCard';
// import { ProductCards } from './ProductCards';

const ProductCards = loadable(() => import('./ProductCards'));
const BodyFeature = loadable(() => import('./BodyFeature'));

export const HomeBody = ({
	personaFeatures,
	personaHeader,
	personaParagraph,
	productCards,
	productCtaLink,
	productCtaText,
	productCardBackground,
	productHeader,
	logoLeftCta,
}) => {
	const med = useMediaQuery('(max-width: 960px)');
	//state for selected card for features
	const [selectedFeature, setSelectedFeature] = useState(null);
	const featureRef = useRef(null);

	const handleSelectFeature = async (e, content) => {
		await setSelectedFeature(content._key);
		// setToFirst(content);
		featureRef?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		setSelectedFeature(personaFeatures[0]?._key);
	}, []);

	return (
		<>
			<Container style={{ paddingBottom: '4rem' }}>
				{!med ? (
					<BodyFeatureCard
						featureHeader={personaHeader}
						personaParagraph={personaParagraph}
						featureSection={personaFeatures}
						selectedFeature={selectedFeature}
						handleClick={handleSelectFeature}
						featureRef={featureRef}
						// featureListCta={softwareFeatures?.featureListCta}
						// setFeatureModalOpen={setFeatureModalOpen}
						accentColor='#002D5C'
					/>
				) : (
					<Grid container style={{ paddingTop: '2rem' }}>
						<Typography variant='h2' color='primary'>
							{personaHeader}
						</Typography>
						<Typography variant='body1'
						style={{ margin: '1rem 0' }}>
							{personaParagraph}
						</Typography>
					</Grid>
				)}
				{!med ? (
					personaFeatures
						.filter((feature) => feature._key === selectedFeature)
						.map((feature, index) => (
							<BodyFeature
								feature={feature}
								index={index}
								key={index}
								accentColor='#002D5C'
							/>
						))
				) : (
					<MobileFeatures featureSection={personaFeatures} />
				)}
			</Container>
			<ProductCards
				productCardBackground={productCardBackground}
				productHeader={productHeader}
				productCards={productCards}
				productCtaLink={productCtaLink}
				productCtaText={productCtaText}
				logoLeftCta={logoLeftCta}
			/>
		</>
	);
};
