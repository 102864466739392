import React from 'react';
import { Link } from 'gatsby';

import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { IndustryBlurb } from './IndustryBlurb';

const useStyles = makeStyles((theme) => ({
	header: {
		textAlign: 'center',
		paddingBottom: '4rem',
		[theme.breakpoints.down('md')]: {
			textAlign: 'left',
		},
	},
}));

export const Industry = ({
	industriesHeader,
	industries,
	industryCtaText,
	industryCtaLink,
}) => {
	const classes = useStyles();
	return (
		<div style={{ padding: '4rem 0' }}>
			<Typography variant='h2' color='primary' className={classes.header}>
				{industriesHeader}
			</Typography>
			<Grid container spacing={4}>
				{industries.edges
					.filter((industry) => industry.node.homeSection)
					.map((industry, index) => (
						<IndustryBlurb industry={industry.node} key={index} />
					))}
			</Grid>
			<Grid
				container
				justifyContent='center'
				alignItems='center'
				style={{ paddingTop: '4rem' }}>
				<Link to={`/${industryCtaLink}`} style={{ textDecoration: 'none' }}>
					<Button color='primary' variant='contained' size='large'>
						{industryCtaText}
					</Button>
				</Link>
			</Grid>
		</div>
	);
};
