import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '1.3rem',
		fontWeight: 'bold',
		color: theme.workwaveBlue,
		textAlign: 'center',
		lineHeight: '1',
		margin: '0 auto',
		position: 'absolute',
		padding: '0 5px',
		left: '50px',
		right: '0',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem',
		},
	},
	card: {
		// border: '1px solid #ECECEE',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.10)',
		userSelect: 'none',
		borderRadius: '8px',
		cursor: 'pointer',
		background: theme.white,
		width: '100%',
		transition: 'transform 0.25s, box-shadow 0.75s',
		margin: '0 auto',
		position: 'relative',
		border: '2px solid #E5EAF4',
		paddingRight: '30px',
		'&:last-of-type': {
			paddingRight: '0px',
		},
	},
	cardGrid: {
		marginBottom: '30px',
		paddingRight: '30px',
		'&:last-of-type': {
			paddingRight: '0px',
		},
	},
	cardMargin: {
		marginBottom: '26px',
	},
}));

export const BodyFeatureCard = ({
	featureHeader,
	personaParagraph,
	featureSection,
	selectedFeature,
	key,
	handleClick,
	featureRef,
	featureListCta,
	setFeatureModalOpen,
	accentColor,
}) => {
	const classes = useStyles();
	return (
		<div style={{ padding: '2rem 0 4rem' }} ref={featureRef}>
			<Grid
				container
				direction='row'
				justifyContent='center'
				style={{ padding: '2rem 0' }}>
				<Typography
					variant='h2'
					style={{ color: '#002D5C', fontWeight: 600, fontSize: '1.8rem' }}>
					{featureHeader}
				</Typography>
				<Typography variant='body1'
				style={{ margin: '1rem 0' }}>
							{personaParagraph}
					</Typography>
			</Grid>
			<Grid container direction='row' justifyContent='space-between'>
				{featureSection.map((content, index) => (
					<Grid
						style={{ padding: '0 14px' }}
						item
						container
						xs={12}
						md={featureSection.length === 4 ? 3 : 4}
						key={index}
						className={
							featureSection.length > 4 && index < 3
								? classes.cardMargin
								: 'cardGrid'
						}>
						<div
							className={classes.card}
							role="button"
              tabIndex={0}
							style={
								selectedFeature === content._key
									? {
											// border: '1px solid #ECECEE',
											// transform: 'scale(1.04)',
											transition: 'transform 0.25s, box-shadow 0.75s',
											boxShadow:
												'0 16px 30px -8px rgba(50, 50, 93, 0.2), 0 11px 19px -11px rgba(0, 0, 0, 0.3)',
									  }
									: null
							}>
							<Grid
								onClick={(e) => handleClick(e, content)}
								role="button"
                tabIndex={0}
								container
								direction='row'
								justifyContent='flex-start'
								alignItems='center'>
								<FontAwesomeIcon
									icon={['fad', content.cardFaIcon]}
									style={{
										height: '50px',
										width: '50px',
										border: `1px solid #E5EAF4`,
										background: '#E5EAF4',
										padding: '6px',
										color: content.cardIconColor?.hexValue ?? '#002D5C',
										// borderRadius: '8px 0 0 8px',
									}}
								/>
								<Typography className={classes.title}>
									{content.cardTitle}
								</Typography>
							</Grid>
						</div>
					</Grid>
				))}
			</Grid>
			{featureListCta && (
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='center'>
					<Button
						variant='contained'
						size='small'
						onClick={(e) => setFeatureModalOpen(true)}
						style={{
							background: accentColor ?? '#002D5C',
							color: 'white',
							marginTop: '2rem',
						}}>
						{featureListCta}
					</Button>
				</Grid>
			)}
		</div>
	);
};
